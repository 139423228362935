.App {
  text-align: center;
  background-image: url('./FrightfulBackgrounds/wizardChamber.jpg');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Comic Sans", cursive;
  font-size: calc(10px + 2vmin);
  color: red;
}