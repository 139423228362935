.header-container {
    text-shadow:
        0.07em 0 black,
        0 0.07em black,
        -0.07em 0 black,
        0 -0.07em black;
    display: flex;
    flex-direction: row;
    max-height: 250px;
    z-index: 999;
    
    .header-text {
        font-size: 48px;
        margin:auto;
    }
}