.results-popup-container {
    position: absolute;
    background-color: hotpink;
    top: 26%;
    width: 40%;
    z-index: 1000;

    li {
        font-size: 32px;
        text-align: left;
        padding-left: 5%;
    }
    
    .results-popup-button {
        background-color: red;
        width:100%;
        font-size: 20px;
        font-family: "Comic Sans", cursive;
    }
}