.decklist-input-container {
    text-shadow:
        0.07em 0 black,
        0 0.07em black,
        -0.07em 0 black,
        0 -0.07em black;
    margin: auto;
    margin-top: 50px;
    z-index: 999;

    .decklist-input-header {
        font-size: 30px;
        margin-bottom: 5px;
    }

    .decklist-input-body {
        display: flex;
        flex-direction: column;
        width: 65%;
        margin: auto;

        .decklist-input-field {
            color: yellow;
            background-color: black;
            font-family: "Comic Sans", cursive;
            font-size: 20px;
            min-height: 500px;
            overflow-y: scroll;
        }

        .decklist-input-button {
            background-color: red;
            font-size: 28px;
            font-family: "Comic Sans", cursive;
        }
    }
}