.landing-page-container {
    background-image: url('../../FrightfulBackgrounds/landingCastle.jpg');
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:absolute;
    background-color: green;
    z-index: 2000;

    .landing-page-body {
        margin: auto 0;
    }

    .landing-button {
        background-color: red;
        font-family: 'Comic Sans', cursive;
        font-size: 20px;
        height: 40px;
    }
}